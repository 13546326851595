import { Model } from '@components';

const alexi: Model = {
  background: require('@assets/media/models/alexi_background.png'),
  softColor: require('@assets/media/models/alexi_soft_color.png'),
  color: require('@assets/media/models/alexi_color.png'),
  correction: require('@assets/media/models/alexi_correction.png'),
};

const emily: Model = {
  background: require('@assets/media/models/emily_background.png'),
  softColor: require('@assets/media/models/emily_soft_color.png'),
  color: require('@assets/media/models/emily_color.png'),
  correction: require('@assets/media/models/emily_correction.png'),
};

const sandra: Model = {
  background: require('@assets/media/models/sandra_background.png'),
  softColor: require('@assets/media/models/sandra_soft_color.png'),
  color: require('@assets/media/models/sandra_color.png'),
  correction: require('@assets/media/models/sandra_correction.png'),
};

const yasmin: Model = {
  background: require('@assets/media/models/yasmin_background.png'),
  softColor: require('@assets/media/models/yasmin_soft_color.png'),
  color: require('@assets/media/models/yasmin_color.png'),
  correction: require('@assets/media/models/yasmin_correction.png'),
};

const monia1: Model = {
  background: require('@assets/media/models/monia1_background.png'),
  softColor: require('@assets/media/models/monia1_soft_color.png'),
  color: require('@assets/media/models/monia1_color.png'),
  correction: require('@assets/media/models/monia1_correction.png'),
};

const monia2: Model = {
  background: require('@assets/media/models/monia2_background.png'),
  softColor: require('@assets/media/models/monia2_soft_color.png'),
  color: require('@assets/media/models/monia2_color.png'),
  correction: require('@assets/media/models/monia2_correction.png'),
};

const Ellure_ModelMap: { [skintone: string]: Model } = {
  '#F0CABF': alexi,
  '#F1D9D7': emily,
  '#EDCFC2': sandra,
  '#E0B293': yasmin,
  '#795E57': monia1,
};

const nail_00: Model = {
  background: require('@assets/media/models/Depend/nail-model-00.png'),
  softColor: require('@assets/media/models/Depend/nail-model-00_soft_color.png'),
  color: require('@assets/media/models/Depend/nail-model-00_color.png'),
  correction: require('@assets/media/models/Depend/nail-model-00_correction.png'),
};

const nail_01: Model = {
  background: require('@assets/media/models/Depend/nail-model-01.png'),
  softColor: require('@assets/media/models/Depend/nail-model-01_soft_color.png'),
  color: require('@assets/media/models/Depend/nail-model-01_color.png'),
  correction: require('@assets/media/models/Depend/nail-model-01_correction.png'),
};

const nail_02: Model = {
  background: require('@assets/media/models/Depend/nail-model-02.png'),
  softColor: require('@assets/media/models/Depend/nail-model-02_soft_color.png'),
  color: require('@assets/media/models/Depend/nail-model-02_color.png'),
  correction: require('@assets/media/models/Depend/nail-model-02_correction.png'),
};

const nail_03: Model = {
  background: require('@assets/media/models/Depend/nail-model-03.png'),
  softColor: require('@assets/media/models/Depend/nail-model-03_soft_color.png'),
  color: require('@assets/media/models/Depend/nail-model-03_color.png'),
  correction: require('@assets/media/models/Depend/nail-model-03_correction.png'),
};

const nail_04: Model = {
  background: require('@assets/media/models/Depend/nail-model-04.png'),
  softColor: require('@assets/media/models/Depend/nail-model-04_soft_color.png'),
  color: require('@assets/media/models/Depend/nail-model-04_color.png'),
  correction: require('@assets/media/models/Depend/nail-model-04_correction.png'),
};

const Depend_ModelMap: { [skintone: string]: Model } = {
  '#f1d9d8': nail_00,
  '#f1cfba': nail_01,
  '#e0b394': nail_02,
  '#84593f': nail_03,
  '#38120a': nail_04,
};

export { Ellure_ModelMap, Depend_ModelMap };
