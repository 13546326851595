import { Box, useRadio, UseRadioProps } from '@chakra-ui/react';

export function Swatch(
  props: UseRadioProps & { shade: string; size: 'lg' | 'sm'; height?: string | number; width?: string | number },
) {
  const { state, getInputProps, getCheckboxProps } = useRadio(props);
  const input = getInputProps();
  const checkbox = getCheckboxProps();

  return (
    <Box
      as='label'
      flex={props.size === 'lg' ? '1 0 21%' : '1 0 10%'}
      height={props.height}
      width={props.width}
      aspectRatio='1/1'
      backgroundColor={props.shade}
      borderRadius={'50%'}
      transition={'0.3s'}
      outline={'0px solid #fff'}
      transitionProperty={'border-radius, outline'}
      style={
        state.isChecked
          ? {
              zIndex: 1,
              outline: '6px solid #fff',
              boxShadow:
                'rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px',
            }
          : undefined
      }
    >
      <input {...input} />
      <Box {...checkbox} />
    </Box>
  );
}

Swatch.defaultProps = {
  size: 'lg',
};
