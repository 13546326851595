import React from 'react';

import { isHex } from '@utils';

import { LayoutWrapper } from '@components';
import {
  Box,
  Button,
  Flex,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Input,
  InputGroup,
  InputLeftAddon,
  Text,
  useRadio,
  UseRadioProps,
} from '@chakra-ui/react';

import * as API from '@utils/api';

const Swatch = (
  props: UseRadioProps & { shade: string; size: 'lg' | 'sm'; height?: string | number; width?: string | number },
) => {
  const { state, getInputProps, getCheckboxProps } = useRadio(props);
  const input = getInputProps();
  const checkbox = getCheckboxProps();

  return (
    <Box
      as='label'
      flex={props.size === 'lg' ? '1 0 21%' : '1 0 10%'}
      height={props.height}
      width={props.width}
      aspectRatio='1/1'
      backgroundColor={props.shade}
      borderRadius={state.isChecked ? '20px' : '0'}
      transition={'ease-in 0.1s'}
    >
      <input {...input} />
      <Box {...checkbox} />
    </Box>
  );
};

Swatch.defaultProps = {
  size: 'lg',
};

export default function () {
  const [formulation, setFormulation] = React.useState<API.Prediction | null>(null);
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const [shade, setShade] = React.useState('');

  const [_shade, set_Shade] = React.useState('');
  const [name, setName] = React.useState('');
  const [email, setEmail] = React.useState('');

  return (
    <>
      <LayoutWrapper>
        <Grid templateAreas={`"header" "picker" "footer"`} templateColumns={'100%'} gap='0 5vw' height='100%' flex='1'>
          <GridItem p={3} area='header'>
            <Heading textAlign='center'>Confirm your shade</Heading>
            <Text textAlign='center' mt={3}>
              Are you sure about your shade? Let's formulate it!
            </Text>
          </GridItem>
          <GridItem p={3} area='picker' alignSelf={'center'}>
            <Flex
              flexDir='row'
              minH='300px'
              maxH='300px'
              p={3}
              overflow={'hidden'}
              justifyContent={'center'}
              alignItems='center'
              border='1px solid #343434'
              borderRadius={5}
            >
              <Flex gap='0px 120px'>
                <Flex flexDir='column' alignSelf='center'>
                  <Text>
                    <b>Your shade</b>
                  </Text>
                  <InputGroup>
                    <InputLeftAddon>#</InputLeftAddon>
                    <Input
                      value={_shade}
                      onChange={(event) => {
                        set_Shade(event.target.value);
                        if (event.target.value.length < 6) {
                          setShade('');
                          return;
                        }
                        const shade = '#' + event.target.value;
                        if (!isHex(shade)) {
                          setShade('');
                          return;
                        }

                        setShade(shade);
                      }}
                    />
                  </InputGroup>
                  <Button
                    colorScheme='blue'
                    size='lg'
                    isLoading={isSubmitting}
                    isDisabled={isSubmitting || !isHex(shade)}
                    onClick={async () => {
                      setIsSubmitting(true);
                      try {
                        const result = await API.getFormulation({
                          body: [shade],
                          query: { product: 'lipstick', weight: "2.000" },
                        });
                        setFormulation(result.data.predictions[0]);
                      } catch (e) {
                        console.log(e);
                      } finally {
                        setIsSubmitting(false);
                      }
                    }}
                  >
                    Formulate shade
                  </Button>
                </Flex>
                <Flex gap='0px 40px'>
                  <Flex gap='0px 40px'>
                    <Flex flexDir='column'>
                      <Text w='100%' textAlign='center'>
                        <b>Expected shade</b>
                      </Text>
                      <Swatch shade={shade} isChecked width='140px' />
                    </Flex>
                    <Flex flexDir='column'>
                      <Text w='100%' textAlign='center'>
                        <b>Formulated shade</b>
                      </Text>
                      <Swatch shade={formulation?.HEX || ''} isChecked width='140px' />
                    </Flex>
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
          </GridItem>
          <GridItem p={3} area='footer' justifySelf='center'>
            <FormLabel>
              Your name:
              <Input value={name} onChange={(event) => setName(event.target.value)} />
            </FormLabel>
            <FormLabel>
              Your email:
              <Input value={email} type='email' onChange={(event) => setEmail(event.target.value)} />
            </FormLabel>
            <Button
              colorScheme='blue'
              size='lg'
              mt={3}
              isDisabled={isSubmitting || formulation === null || name === '' || email === ''}
              isLoading={isSubmitting}
              onClick={async (e) => {
                setIsSubmitting(true);
                try {
                  if (!formulation) throw new Error('Shade is not formulated!');
                  await API.createProduct({
                    body: {
                      content: [
                        {
                          type: 'lipstick',
                          derivedFrom: {
                            expectedShade: formulation.HEX,
                          },
                        },
                      ],
                      metadata: {
                        email,
                        name,
                        orderDate: new Date().toJSON(),
                      },
                    },
                  });
                  setFormulation(null);
                  setShade('');
                  set_Shade('');
                  setEmail('');
                  setName('');
                } catch (e) {
                  console.log(e);
                } finally {
                  setIsSubmitting(false);
                }
              }}
            >
              Submit
            </Button>
          </GridItem>
        </Grid>
      </LayoutWrapper>
    </>
  );
}
