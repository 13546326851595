import React from 'react';

import { Model, CustomModel } from './';

import * as API from '@utils/api';
import { AxiosError } from 'axios';

import { useResizeObserver, hex2grayscale, sleep, useMediaQuery } from '@utils';

import {
  Button,
  Text,
  Heading,
  ButtonGroup,
  Image,
  Box,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Modal,
  useDisclosure,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Grid,
  GridItem,
  IconButton,
  Flex,
} from '@chakra-ui/react';
import { Loader } from '@components';

import { ReactComponent as LightningIcon } from '@assets/media/guidelines/lighting.svg';
import { ReactComponent as ExpressionIcon } from '@assets/media/guidelines/face-expression.svg';
import { ReactComponent as LipstickIcon } from '@assets/media/guidelines/lipstick.svg';
import { ReactComponent as PositionIcon } from '@assets/media/guidelines/positioning.svg';
import { RiCamera3Line, RiUserStarLine } from 'react-icons/ri';

const modelStyle: React.CSSProperties = {
  width: '100%',
  height: '100%',
  margin: 0,
  position: 'relative',
  left: 0,
  top: 0,
};

const divFilterStyle: React.CSSProperties = {
  position: 'absolute',
  zIndex: 3,
  top: 0,
  height: '100%',
  backgroundSize: 'inherit',
  backgroundPosition: 'inherit',
  backgroundRepeat: 'inherit',

  WebkitMaskSize: 'contain',
  WebkitMaskPosition: 'center',
  WebkitMaskRepeat: 'no-repeat',
  maskSize: 'contain',
  maskPosition: 'center',
  maskRepeat: 'no-repeat',
  transition: 'left 1s, background-color 0.25s',
};

const imgFilterStyle: React.CSSProperties = {
  maxWidth: 'none',
  height: '100%',
  position: 'absolute',

  top: 0,
  zIndex: 1,
  transition: 'left 0.3s',
};

class ViewerError extends Error {
  constructor(message: string) {
    super();
    this.message = message;
  }
}

const Previewer = {
  Model: function (props: {
    model: Model;
    dimensions: DOMRectReadOnly;
    color: string;
    positionX: number;
    onLoaded: () => void;
  }) {
    const { model, color, dimensions, positionX } = props;
    const imageWidth = 1600 * (dimensions.height / 1200);
    const offset = (dimensions.width - imageWidth) * 0.5 - dimensions.width * positionX;

    return (
      <Box id='model' style={modelStyle}>
        <Box
          id='filter-soft-color'
          backgroundColor={color}
          left={offset}
          pl={dimensions.height}
          minW={imageWidth}
          maxW={imageWidth}
          style={{
            ...divFilterStyle,
            WebkitMaskImage: `url('${model.softColor}')`,
            maskImage: `url('${model.softColor}')`,
            WebkitMaskSize: 'contain',
            mixBlendMode: 'multiply', //color',
          }}
        />
        <Box
          id='filter-color'
          backgroundColor={color}
          left={offset}
          pl={dimensions.height}
          minW={imageWidth}
          maxW={imageWidth}
          style={{
            ...divFilterStyle,
            WebkitMaskImage: `url('${model.color}')`,
            maskImage: `url('${model.color}')`,
            WebkitMaskSize: 'contain',
          }}
        />
        <Box
          id='filter-correction'
          backgroundColor='#000'
          left={offset}
          pl={dimensions.height}
          minW={imageWidth}
          maxW={imageWidth}
          opacity={255 - hex2grayscale(color) / 255}
          style={{
            ...divFilterStyle,
            WebkitMaskImage: `url('${model.correction}')`,
            maskImage: `url('${model.correction}')`,
            WebkitMaskSize: 'contain',
            mixBlendMode: 'soft-light',
          }}
        />
        <Image
          src={model.background}
          alt='model'
          height='100%'
          position='absolute'
          top='0'
          maxW='unset'
          left={offset}
          transition='left 1s'
          onLoad={props.onLoaded}
        />
      </Box>
    );
  },
  Custom: function (props: {
    model: CustomModel;
    dimensions: DOMRectReadOnly;
    color: string;
    positionX: number;
    onLoaded: () => void;
  }) {
    const canvas = React.useRef<HTMLCanvasElement>(null);
    const [image, imgDimensions] = useResizeObserver();

    const { model, color, dimensions, positionX } = props;
    const offset = (dimensions.width - (imgDimensions?.width || 2000)) * 0.5;

    React.useEffect(() => {
      if (!canvas.current) return;
      const ctx = canvas.current.getContext('2d');
      if (!ctx) return;

      ctx.canvas.width = imgDimensions?.width || 1000;
      ctx.canvas.height = imgDimensions?.height || 1600;

      let ratio = 1.5;
      let img = document.getElementById('custom-model') as HTMLImageElement | null;
      if (img) {
        ratio = ctx.canvas.width / img.naturalWidth;
      }

      const imageIdArr = {
        'lips-img1': color,
        'lips-img2': color,
        'lips-img3': 'rgba(0,0,0,0.75)',
      };

      Object.entries(imageIdArr).forEach(([id, color]) => {
        ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);

        ctx.fillStyle = color;
        ctx.beginPath();
        model.landmarks.topLip.forEach((point, i) => {
          if (i === 0) return ctx.moveTo(point.x * ratio, point.y * ratio);
          ctx.lineTo(point.x * ratio, point.y * ratio);
        });
        model.landmarks.bottomLip.forEach((point, i) => {
          if (i === 0) return ctx.moveTo(point.x * ratio, point.y * ratio);
          ctx.lineTo(point.x * ratio, point.y * ratio);
        });
        ctx.fill();

        let myImage = ctx.canvas.toDataURL('image/png');
        let imageElement = document.getElementById(id) as HTMLImageElement | null;
        if (imageElement) imageElement.src = myImage;
      });

      props.onLoaded();
    }, [canvas, model, color, imgDimensions?.height, imgDimensions?.width]);

    return (
      <Box id='model' style={modelStyle}>
        <canvas
          ref={canvas}
          style={{
            height: '100%',
            position: 'absolute',
            display: 'block',
            top: 0,
            left: 0,
            opacity: 0,
          }}
        />
        <Image
          id='lips-img1'
          src={model.image as string}
          alt='filter'
          style={{
            ...imgFilterStyle,
            left: offset,
            filter: `blur(2px)`,
            mixBlendMode: 'multiply', //'color',
            opacity: 0.72,
          }}
        />
        <Image
          id='lips-img2'
          src={model.image as string}
          alt='filter'
          style={{
            ...imgFilterStyle,
            left: offset,
            filter: `blur(5px)`,
            mixBlendMode: 'normal',
            opacity: 0.32,
          }}
        />
        <Image
          id='lips-img3'
          src={model.image as string}
          alt='filter'
          style={{
            ...imgFilterStyle,
            left: offset,
            filter: `blur(1px)`,
            mixBlendMode: 'soft-light',
            opacity: 0.2,
          }}
        />
        <Image
          id='custom-model'
          src={model.image as string}
          alt='model'
          style={{
            height: '100%',
            position: 'absolute',
            top: 0,
            maxWidth: 'none',
            transition: 'left 0.3s',
            left: offset,
          }}
          ref={image}
        />
      </Box>
    );
  },
};

export function Viewer(props: {
  models: { [skinTone: string]: Model };
  color: string;
  onError: (message?: string) => void;
  breakpoint?: string;
  allowCustomModel?: boolean;
}) {
  const isLarge = useMediaQuery(props.breakpoint || '750px');
  const [viewer, viewDimensions] = useResizeObserver();
  const [isLoading, setLoading] = React.useState(true);
  const [isUploading, setUploading] = React.useState(false);
  const [skinTone, setSkintone] = React.useState(Object.keys(props.models)[0]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const imageInput = React.useRef<HTMLInputElement>(null!);
  const [customModel, setCustomModel] = React.useState<CustomModel | null>(null);

  React.useEffect(() => {
    setLoading(true);
  }, [skinTone]);

  React.useEffect(() => {
    if (!imageInput.current) return;

    const onFocus = async () => {
      await sleep(500);
      try {
        const input = imageInput.current;
        if (input.files && input.files[0]) {
          const BrowserImageResizer = require('browser-image-resizer');
          const image = await BrowserImageResizer.readAndCompressImage(input.files[0], {
            quality: 0.8,
            maxHeight: 1600,
            maxWidth: 3000,
            autoRotate: false,
          });
          image.name = input.files[0].name;

          const response = await API.getLandMarks({
            body: {
              image,
            },
          });

          if (response.data.bottomLip.length === 0 || response.data.topLip.length === 0)
            throw new ViewerError(
              'No face detected on selected photo. Please, check if the photo meets the guidelines and try it again.',
            );

          var reader = new FileReader();
          reader.addEventListener('load', (e: ProgressEvent<FileReader>) => {
            if (!e.target?.result) throw new ViewerError('Selected photo could not be loaded.');
            setCustomModel({
              image: e.target.result,
              landmarks: response.data,
            });
            setUploading(false);
          });
          reader.readAsDataURL(image);
        }
      } catch (e) {
        console.log(e);
        if (e instanceof ViewerError) props.onError(e.message);
        else if (e instanceof AxiosError)
          if (e.request.status === 415)
            props.onError('Photo format is not allowed or could not be determined, please try another picture.');
          else props.onError('Something went wrong on our end to analyze the photo, please try again later.');
        else if (e instanceof Error) props.onError(e.message);
        else props.onError();
      }

      setUploading(false);
    };

    if (isUploading) {
      window.addEventListener('focus', onFocus);

      return () => {
        window.removeEventListener('focus', onFocus);
      };
    }
  }, [imageInput, isUploading]);

  return (
    <>
      <input ref={imageInput} id='face-image' type='file' hidden accept='image/*' />
      <Box ref={viewer} position='relative' height='100%' width='100%' overflow='hidden'>
        <ButtonGroup position='absolute' left='0' top='0' p={3} zIndex={11}>
          {props.allowCustomModel && (
            <IconButton
              onClick={onOpen}
              size={isLarge ? 'lg' : 'sm'}
              aria-label='Upload photo'
              title='upload photo'
              icon={<RiCamera3Line />}
              isRound={true}
              variant='solid'
            />
          )}
          <Menu>
            <MenuButton
              as={IconButton}
              icon={<RiUserStarLine />}
              size={isLarge ? 'lg' : 'sm'}
              title='Select model'
              aria-label='Select model'
              isRound={true}
              variant='solid'
            />
            <MenuList backgroundColor='unset' border='unset' pt={0} pb={0} boxShadow='0'>
              {Object.keys(props.models).map((tone, i) => (
                <MenuItem
                  key={i}
                  backgroundColor={tone}
                  mb={isLarge ? '8px' : '2px'}
                  borderRadius='full'
                  w={isLarge ? '48px' : '32px'}
                  h={isLarge ? '48px' : '32px'}
                  border={tone === skinTone ? '5px solid rgb(242, 242, 242)' : 'none'}
                  onClick={() => {
                    setCustomModel(null);
                    setSkintone(tone);
                  }}
                />
              ))}
            </MenuList>
          </Menu>
        </ButtonGroup>
        <Loader isLoading={isLoading || isUploading} style={{ position: 'relative', height: '100%', width: '100%' }}>
          {viewDimensions && customModel && (
            <Previewer.Custom
              color={props.color}
              positionX={0.25 * 0}
              dimensions={viewDimensions}
              model={customModel}
              onLoaded={() => setLoading(false)}
            />
          )}
          {viewDimensions && !customModel && (
            <Previewer.Model
              color={props.color}
              positionX={0.25 * 0}
              dimensions={viewDimensions}
              model={props.models[skinTone]}
              onLoaded={() => setLoading(false)}
            />
          )}
        </Loader>

        <Modal
          isOpen={isOpen}
          onClose={onClose}
          isCentered={isLarge ? true : false}
          size={isLarge ? 'lg' : 'full'}
          scrollBehavior='inside'
          motionPreset='scale'
        >
          <ModalOverlay />
          <ModalContent maxW={{ base: 'auto', web: '600px' }}>
            <ModalHeader>Picture guidelines</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Text>
                For the best results and no unwanted errors, make sure your image meets the following guidelines:
              </Text>
              <Grid
                templateAreas={`"guide1 guide2" "guide3 guide4"`}
                templateColumns={'1fr 1fr'}
                templateRows={'1fr 1fr'}
                pt={4}
                gap={4}
              >
                <GridItem as={Flex} area='guide1' flexDir='column'>
                  <LightningIcon style={{ width: '150px', alignSelf: 'center', marginBottom: '15px' }} />
                  <Heading as='h3' size='sm'>
                    NATURAL LIGHTING
                  </Heading>
                  <Text fontSize='1.3rem'>
                    Make sure that the lightning is natural as it could make the color appear differently than in real
                    life.
                  </Text>
                </GridItem>
                <GridItem as={Flex} area='guide2' flexDir='column'>
                  <PositionIcon style={{ width: '150px', alignSelf: 'center', marginBottom: '15px' }} />
                  <Heading as='h3' size='sm'>
                    FACE CENTERED
                  </Heading>
                  <Text fontSize='1.3rem'>
                    Make sure that your face is properly centered and at a good distance from the camera (about 30cm).
                  </Text>
                </GridItem>
                <GridItem as={Flex} area='guide3' flexDir='column'>
                  <ExpressionIcon style={{ width: '150px', alignSelf: 'center', marginBottom: '15px' }} />
                  <Heading as='h3' size='sm'>
                    NATURAL FACE EXPRESSION
                  </Heading>
                  <Text fontSize='1.3rem'>
                    The AR is not perfect, for a good preview take a picture with a natural face expression.
                  </Text>
                </GridItem>
                <GridItem as={Flex} area='guide4' flexDir='column'>
                  <LipstickIcon style={{ width: '150px', alignSelf: 'center', marginBottom: '15px' }} />
                  <Heading as='h3' size='sm'>
                    NO LIPSTICK
                  </Heading>
                  <Text fontSize='1.3rem'>
                    Do not wear lipstick as this can influence the shades that you will see.
                  </Text>
                </GridItem>
              </Grid>
            </ModalBody>

            <ModalFooter>
              <ButtonGroup>
                <Button onClick={onClose} variant='ghost'>
                  Close
                </Button>
                <Button
                  colorScheme='blue'
                  onClick={() => {
                    imageInput.current.click();
                    setUploading(true);
                    onClose();
                  }}
                >
                  Upload picture
                </Button>
              </ButtonGroup>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Box>
    </>
  );
}
