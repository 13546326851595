import { defineStyle, extendTheme } from '@chakra-ui/react';

export const breakpoints = {
  base: '0px',
  web: '750px',
};

const theme = extendTheme({
  colors: {
    depend: {
      500: '#da6868',
    },
    dependOpposite: {
      500: '#7981DB',
    }
  },
  components: {
    Heading: {
      baseStyle: ({ as }: { as: string }) => {
        switch (as) {
          case 'h1':
            return defineStyle({
              fontSize: '5xl',
              letterSpacing: '1px',
              color: '#595959',
              fontWeight: 400,
              fontFamily: 'Libre Caslon Text, sans-serif',
            });
        }
      },
    },
    Text: {
      baseStyle: {
        color: '#595959',
        fontFamily: 'Work Sans, sans-serif',
      },
    },
    Button: {
      baseStyle: {
        fontFamily: 'Work Sans, sans-serif',
        letterSpacing: '1px',
      },
    },
  },
  breakpoints,
  styles: {
    global: {
      '*': {
        boxSizing: 'border-box',
      },
      'html, body, #root': {
        height: '100%',
      },
      'html, body': {
        margin: 0,
        padding: 0,
        fontFamily: 'sans-serif',
      },
      '.wrapper': {
        padding: '5vh 3vw',
      },
    },
  },
});

export default theme;
