import { HSL, hsl2hex } from '@utils';

import { Box, Text, Slider, SliderFilledTrack, SliderThumb, SliderTrack } from '@chakra-ui/react';

export function ColorSliderInput(props: {
  value: number;
  onChange: (value: number) => void;
  label: string;
  domain: [min: number, max: number, stepsize: number];
  hsl: HSL;
  colorKeys: [string, string, string];
  showLabel: boolean;
}) {
  return (
    <Box>
      {props.showLabel && <Text>{props.label}</Text>}
      <Slider
        aria-label={`slider-${props.label}`}
        defaultValue={30}
        value={props.value}
        min={props.domain[0]}
        max={props.domain[1]}
        step={props.domain[2]}
        onChange={props.onChange}
      >
        <SliderTrack
          h='1.2rem'
          borderRadius='full'
          background={`linear-gradient(to right${props.colorKeys.reduce((string, key) => `${string}, ${key}`, '')})`}
        >
          <SliderFilledTrack opacity='0' />
        </SliderTrack>
        <SliderThumb
          h='2.9rem'
          w='1.5rem'
          boxShadow='0 1px 3px 0 rgba(0,0,0,.55)'
          border='3px solid #fff'
          backgroundColor={hsl2hex(...props.hsl)}
        />
      </Slider>
    </Box>
  );
}
